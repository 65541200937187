import {useEffect, useState} from 'react';
import strapiDao from '../../dao/strapiDao';
import StrapiUtils from './strapiUtils';
import langCode from '../../utils/langCode';
import {useTranslation} from 'react-i18next';
import _isObject from 'lodash/isObject';
import _get from 'lodash/get';
import webUtils from '../../utils/webUtils';
import demostoreUtils from '../../utils/demostoreUtils';

export default function GetStrapiContent(
  type,
  setIsLoaded,
  location,
  defaultEnu = false
) {
  const [pfFormId, setPfFormId] = useState('');
  const {t, i18n} = useTranslation();
  const params = new URLSearchParams(location.search);
  const [strapiContent, setStrapiContent] = useState({});
  const languageType = langCode.convertLanguageCode(i18n.language || 'en');
  const cmsId = params.get('cmsId');
  const isEnd = webUtils.isMakeupArUrl() && languageType === 'enu';
  useEffect(() => {
    window.addEventListener('message', event => {
      if (demostoreUtils.isDemostore() && !webUtils.isMsrFormPreview()) {
        return;
      }
      if (!_get(event, 'data') || _isObject(event.data)) return;
      console.log('event: ', event);
      setPfFormId(event.data);
    });
  }, []);
  useEffect(() => {
    if (
      type === 'YOU_CAM_VIDEO' &&
      window.location.pathname.indexOf('ycv') === -1
    ) {
      return;
    }
    if (cmsId) {
      strapiDao
        .getProductPageById({
          type: type,
          id: cmsId,
        })
        .then(res => {
          if (!res) return;
          setStrapiContent(StrapiUtils.SectionCombine(res.attributes));
          setIsLoaded(true);
        })
        .catch(err => {
          console.log({err});
        });
      return;
    }
    strapiDao
      .getProductPageByLanguage(
        {
          type: type,
          languageType: isEnd ? 'end' : languageType,
          defaultEnu: isEnd || defaultEnu,
        },
        pfFormId
      )
      .then(res => {
        if (res) setStrapiContent(StrapiUtils.SectionCombine(res.attributes));
        setIsLoaded(true);
      })
      .catch(err => {
        console.log({err});
      });
  }, [languageType, pfFormId]);

  return {strapiContent};
}
